import { Collapse, CollapseProps, theme } from 'antd';
import styles from './index.module.less';
import { CSSProperties, useState } from 'react';
import { CommonBackContent } from '@/components/Layouts/CommonBackContent';
import { useTranslation } from 'next-i18next';
import FavoriteInfluencerAlert from '../InfluencersContent/FavoriteInfluencerAlert';

const FAQContent = ({
  isFromHome = false,
  pageContent,
}: {
  isFromHome?: boolean;
  pageContent?: any;
}) => {
  const { t } = useTranslation('common');
  const { token } = theme.useToken();
  const [open, setOpen] = useState(false);

  const en_dataList = [
    {
      label: 'What is Hairpaca?',
      children: [
        `Hairpaca provides the latest trendy hairstyles, allowing you to virtually try them on without actually cutting your hair. Become the fashion leader in your social circle.`,
      ],
    },
    {
      label: 'How does Hairpaca work?',
      children: [
        `Choose any hairstyle you like, click "Try on," and upload your photo. You'll see the magic happen in seconds!`,
      ],
    },
    {
      label: 'How does Hairpaca handle the personal photos I upload?',
      children: [
        `You can choose to save your personal photos for future use or opt to have Hairpaca delete them immediately after use. The choice is yours.`,
      ],
    },
    {
      label: 'Can I try on any hairstyle available on Hairpaca?',
      children: [`Yes, you can try on any hairstyle you like.`],
    },
    {
      label: `What if my favorite hairstyle influencer isn't on Hairpaca?`,
      children: [
        <p key={111}>
          You can submit a request{' '}
          <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => setOpen(true)}>
            here
          </span>
          , and we will consider adding them after review.
        </p>,
      ],
    },
    {
      label: 'How long does it take to receive my hairstyle try-on photo?',
      children: [
        `Very fast—usually, you can get a photo in about 5 seconds. If the system is under heavy use, photo generation might take a bit longer. If you haven't received your photo after a long wait, please contact us at help.pumpsoul@gmail.com.`,
      ],
    },
    {
      label: 'Can I share my Hairpaca transformations on social media?',
      children: [
        `Yes! We encourage you to share your new looks with friends and family on social media.`,
      ],
    },
    {
      label: 'Can I suggest new features or hairstyles for Hairpaca?',
      children: [
        `We love hearing from our users! If you have suggestions for new features or specific hairstyles you'd like to see in CoreStyles, please email us at help.pumpsoul@gmail.com.`,
      ],
    },
  ];

  const dataList = () => {
    return en_dataList;
  };

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => {
    return dataList().map((item, index) => {
      return {
        label: item.label,
        children: (
          <div className={styles.infoContainer}>
            {item.children.map((childItem, childIndex) => {
              if (typeof childItem === 'string') {
                return (
                  <p
                    key={item.label + childIndex}
                    dangerouslySetInnerHTML={{ __html: childItem }}
                  />
                );
              } else {
                return <p key={item.label + childIndex}>{childItem}</p>;
              }
            })}
          </div>
        ),
        key: `${index}`,
        style: panelStyle,
      };
    });
  };

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: `var(--ground-rgb-1)`,
    borderRadius: token.borderRadiusLG,
    border: 'none',
    color: 'red',
    boxShadow: `0 0 2px #D9D9D9`,
  };

  return (
    <CommonBackContent style={{ padding: 0 }}>
      <div className={styles.content}>
        <div className={styles.topContainer}>
          {!isFromHome && (
            <h1>
              <span>{t('FREQUENTLY ASKED QUESTIONS')}</span>
            </h1>
          )}
          {isFromHome && (
            <h2>
              <span>{t('FAQ About Hairpaca')}</span>
            </h2>
          )}
        </div>

        <Collapse
          className={styles.collapseContent}
          bordered={false}
          items={getItems(panelStyle)}
          defaultActiveKey={undefined}
        />
      </div>
      <ul style={{ display: 'none' }}>
        {en_dataList.map((item, index) => {
          if (isFromHome) {
            return <h3 key={index + item.label}>{item.label}</h3>;
          } else {
            return <h2 key={index + item.label}>{t(item.label)}</h2>;
          }
        })}
        <h2>Hairpaca</h2>
        <h3>FEATURES</h3>
        <h3>RESOURCES</h3>
        <h3>PRODUCT</h3>
        <h3>WORK WITH US</h3>
      </ul>

      <FavoriteInfluencerAlert open={open} handleClose={() => setOpen(false)} />
    </CommonBackContent>
  );
};

export default FAQContent;
