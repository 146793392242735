import React, { useState } from 'react';
import styles from './index.module.less';
import { Button, Form, Input, message, Modal } from 'antd';
import { useTranslation } from 'next-i18next';
import { fetchHairstyleBloggerUserSubmitData } from '@/lib/service';
import { useRouter } from 'next/router';
import { useUserProvider } from '@/context/UserProvider';
import { CloseOutlined } from '@ant-design/icons';

const FavoriteInfluencerAlert = ({
  open,
  handleClose,
}: {
  open?: boolean;
  handleClose?: () => void;
}) => {
  // const { message } = App.useApp();
  const { t } = useTranslation('common');
  const [form] = Form.useForm();
  const router = useRouter();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const { isLogin, onOpenLoginModal } = useUserProvider();

  const success = () => {
    messageApi.open({
      type: 'success',
      // icon: <Image src={'/images/hairstyle/send-email-icon.webp'} alt={''} width={36} height={36} />,
      content:
        'Thank You for Your Submission! We will review your suggestion and consider adding them to our platform.',
      className: 'custom-class',
      // style: {
      //   // background: 'red',
      //   display: 'flex',
      //   alignItems: 'center'
      //   // marginTop: '20vh',
      // },
    });
  };

  const onFinish = (values: any) => {
    if (!isLogin) {
      onOpenLoginModal(true);
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);

    fetchHairstyleBloggerUserSubmitData(values?.name, router.locale)
      .then((res) => {
        setLoading(false);
        if (res?.code === 0) {
          success();
          handleClose();
        } else {
          message.error(res?.message);
        }
      })
      .catch((e) => {
        message.error(t(e?.message));
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    // console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      open={open}
      onCancel={handleClose}
      closeIcon={<CloseOutlined style={{ color: 'var(--app-text-color)' }} />}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      footer={null}
      maskClosable={false}
      style={{ padding: 0 }}
    >
      <div className={styles.modalContent}>
        {contextHolder}
        <div className={styles.titleContainer}>{t('Submit Your Favorite Influencer')}</div>
        <Form
          name="basic"
          className={styles.formContent}
          form={form}
          layout={'vertical'}
          onFinish={onFinish}
          autoComplete="off"
          requiredMark={false}
          size={'middle'}
        >
          {/* https://www.instagram.com/nww_eraahmad/ */}
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please Enter the Influencer’s Profile Link' }]}
          >
            <Input
              style={{ height: '50px' }}
              placeholder={t('Enter the Influencer’s Profile Link')}
            />
          </Form.Item>

          <Form.Item>
            <div>
              <img
                src="/images/hairstyle/favorite-influencer-example-icon.webp"
                alt=""
                style={{ width: '100%' }}
              />
            </div>
          </Form.Item>

          <Form.Item className={styles.formSubmitBtnWarp}>
            <Button type="primary" htmlType="submit" loading={loading} className={styles.submitBtn}>
              {t('Submit Now')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default FavoriteInfluencerAlert;
